
import { useKeycloak } from "@baloise/vue-keycloak";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export const RegisterWaitingForAcceptComponent = defineComponent({
  setup() {
    const { t } = useI18n();
    const { keycloak } = useKeycloak();

    const logout = async () => {
      keycloak.logout();
    };
    return {
      t,
      logout,
    };
  },
});
export default RegisterWaitingForAcceptComponent;
